import React from "react";
import "./header.css";
import { useSelector } from "react-redux";

const Header = () => {
    const counts = useSelector((state) => state.implementers.counts);
    const timeUpdated = useSelector((state) => state.implementers.timeUpdated);

    return (
        <div className="header">
            <div className="header_content">
                <div className="header_first_blok">
                    <div className="header_itsg_title">
                        <p className="header_title">ITSG</p>
                        <p className="header_title_2"></p>
                    </div>
                    <div className="header_second_block">
                        <img src="https://itsupport.kz/img/logo.png" alt="Itsupport" />
                        <div className="time_updated_block">
                            <p className="header_time">время обновления</p>
                            <p className="header_time_2">{timeUpdated}</p>
                        </div>
                    </div>
                </div>

                <div className="header_applications">
                    <div>
                        <p className="application_count default_color">{counts?.allApplicationsCount || 0}</p>
                        <p className="application_count_desc default_color">всего заявок</p>
                    </div>
                    <div>
                        <p className="application_count red_color">{counts?.allPlannedCount || 0}</p>
                        <p className="application_count_desc red_color">запланировано</p>
                    </div>
                    <div>
                        <p className="application_count orange_color">{counts?.allWorkingCount || 0}</p>
                        <p className="application_count_desc orange_color">в работе</p>
                    </div>
                    <div>
                        <p className="application_count linear_color">{counts?.allPausedCount || 0}</p>
                        <p className="application_count_desc linear_color">на паузе</p>
                    </div>
                    <div>
                        <p className="application_count green_color">{counts?.allFinishedCount || 0}</p>
                        <p className="application_count_desc green_color">завершено</p>
                    </div>
                    <div>
                        <p className="application_count gray_color">{counts?.allcancledCount || 0}</p>
                        <p className="application_count_desc gray_color">отменено</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
