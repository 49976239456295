import Linia_1_img from "../../assests/imgs/linia.png";
import Linia_2_img from "../../assests/imgs/linia-2.png";
import Linia_3_img from "../../assests/imgs/linia-3.png";
import ApplicationLine from "./ApplicationLine";
import "./main.css";

const linesText = ["I", "II", "III"];
const linesImgs = [Linia_1_img, Linia_2_img, Linia_3_img];

const Main = ({ currentIndex, implementers }) => {


    return <div className="main">{implementers ? <ApplicationLine implementers={implementers} line={linesText[currentIndex]} line_Img={linesImgs[currentIndex]} /> : null}</div>;
};

export default Main;
