import React, { useEffect, useRef } from "react";
import ApplicationRow from "../header/ApplicationRow";
import EmployeRow from "./EmployeRow";

import { useSelector } from "react-redux";
import "./ApplicationLine.css";
import Slider from "@ant-design/react-slick";
import { UPDATE_DELAY_BY_SECOND } from "../../consts/consts";

const ApplicationLine = ({ line, line_Img, implementers }) => {
    const { isLoading } = useSelector((state) => state.ui);

    const sliderRef = useRef();
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 8,
        arrows: false,
        autoplay: !isLoading,
        autoplaySpeed: UPDATE_DELAY_BY_SECOND * 1000,
        vertical: true,
        verticalSwiping: true,
        waitForAnimate: false,
    };
    useEffect(() => {
        /** @type Slider*/
        sliderRef.current.slickGoTo(0, true);
    }, [implementers]);
    return (
        <>
            <div
                className="department_line"
                style={{
                    opacity: isLoading ? 0.6 : 1,
                }}
            >
                <Slider ref={sliderRef} className="for_slick" {...settings}>
                    {implementers.map((implementer) => (
                        <EmployeRow
                            key={implementer.uuid}
                            name={implementer.name_rus}
                            planned={implementer.counts.planned}
                            working={implementer.counts.working}
                            paused={implementer.counts.paused}
                            finished={implementer.counts.finished}
                            cancled={implementer.counts.cancled}
                            apps={implementer.counts.apps}
                        />
                    ))}
                </Slider>
            </div>

            <ApplicationRow lineSupport={line} orangeText={"15-30 мин"} redText={"> 30 мин "} line_Img={line_Img} />
        </>
    );
};

export default ApplicationLine;
