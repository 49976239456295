import { configureStore } from "@reduxjs/toolkit";
import implementerSlice from "../features/implementers/ImplemetnersSlice";
import uiSlice from "../features/ui/uiSlice";

export const store = configureStore({
    reducer: {
        implementers: implementerSlice,
        ui: uiSlice,
    },
});
