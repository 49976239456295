import React from "react";
import "./EmployeRow.css";

const EmployeRow = ({ name, planned, working, paused, finished, cancled, apps }) => {
    const isPlannedNotDefined = Object.keys(planned).length === 0;

    return (
        <div className="table_applications">
            <div className="table_applications_implementers">
                <p className="employee_name">{name}</p>
                <div className="table_applications_counts">
                    <p className="department_employe_count default_color">{apps}</p>
                    <div className="department_employe_count department_employe_count_list">
                        {planned?.red ? <p className={"department_employe_counts bgRed"}>{planned.red}</p> : null}
                        {planned?.orange ? <p className={"department_employe_counts bgOrange"}>{planned.orange}</p> : null}
                        {planned?.normal ? <p className={"default_color"}>{planned.normal}</p> : null}
                        {isPlannedNotDefined ? <p className={"default_color"}>{Object.keys(planned).length}</p> : null}
                    </div>
                    <p className={`department_employe_count ${working.top === 1 ? "top_working_red" : working.top === 2 ? "top_working_orange" : "default_color"}`}>{working.count}</p>
                    <p className="department_employe_count default_color">{paused}</p>
                    <p className={`department_employe_count ${finished.top > 0 ? "top_finished" : "default_color"}`}>{finished.count}</p>
                    <p className="department_employe_count default_color">{cancled}</p>
                </div>
            </div>
        </div>
    );
};

export default EmployeRow;
