import { useEffect, useState } from "react";
import Main from "./components/content/main";
import Header from "./components/header/header";
import Progress from "./components/progressbar/Progress";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, stopList } from "./features/ui/uiSlice";
import Updating from "./components/updating/Updating";
import { scroll } from "./global/utils/utils";
import ErrorBar from "./components/error/Error";
import { getImplementers, getImplementersLoad, refreshData } from "./features/implementers/ImplemetnersSlice";
import { UPDATE_DATA_BY_MINUTE, UPDATE_DELAY_BY_SECOND, UPDATING_DELAY_BY_SECOND } from "./consts/consts";

function App() {
    const dispatch = useDispatch();

    const { implementers } = useSelector((state) => state.implementers);
    const { next, isLoading } = useSelector((state) => state.ui);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [successNextTable, setSuccessNextTable] = useState(true);
    const [succcessScrolling, setSucccessScrolling] = useState(true);
    const [isUpdateData, setIsUpdateData] = useState(false);

    const currentImplementers = implementers ? implementers[currentIndex] : [];
    const lastIndex = currentIndex === 2;

    const timeUpdate = UPDATE_DATA_BY_MINUTE * 60000;
    const nextPageDelay = UPDATE_DELAY_BY_SECOND * 1000 - 1;

    useEffect(() => {
        setInterval(() => {
            dispatch(getImplementersLoad());
            // setIsUpdateData(true);
        }, timeUpdate);
    }, []);

    const nextImplementers = () => {
        if (lastIndex) {
             dispatch(refreshData());
            setCurrentIndex(0);
        }
        else setCurrentIndex(currentIndex + 1);
    };

    const updateData = () => {
        dispatch(setLoading(true));
        dispatch(getImplementers());
        setCurrentIndex(0);
        setSuccessNextTable(true);
        setSucccessScrolling(true);
    };

    useEffect(() => {
        if (!implementers?.length) dispatch(getImplementers());
        else setTimeout(() => dispatch(setLoading(false)), UPDATING_DELAY_BY_SECOND * 1000);
    }, [implementers]);

    useEffect(() => {
        setSucccessScrolling(true);
    }, [currentIndex]);

    useEffect(() => {
        if (!isLoading) {
            if (next) {
                if (successNextTable) {
                    if (currentImplementers.pages && succcessScrolling) {
                        setSuccessNextTable(false);
                        setSucccessScrolling(false);
                        setTimeout(() => {
                            setSuccessNextTable(true);
                        }, nextPageDelay * (currentImplementers.pages - 1));
                        scroll();
                    } else {
                        if (lastIndex && isUpdateData) {
                            updateData();
                            setIsUpdateData(false);
                        } else nextImplementers();
                    }
                } else {
                    scroll();
                }
            }
            dispatch(stopList());
        }
    }, [next]);

    return (
        <>
            <Header />
            {isLoading ? <Updating /> : implementers.length ? <Progress /> : null}
            {<Main implementers={implementers[currentIndex]?.list} currentIndex={currentIndex} />}
        </>
    );
}

export default App;
