import React from "react";
import "./ApplicationRow.css";

const ApplicationRow = ({ lineSupport, orangeText, redText, line_Img }) => {
    return (
        <div className="department_block">
            <div className="department_block_content">
                <img className="department_block_img" src={line_Img} alt={lineSupport + " линия"} />
                <span className="department_text">{lineSupport} линия удалённой поддержки</span>
            </div>
            <div className="departments dark-gray_color">
                <div className="departments_item">
                    <div className="department_header">
                        <div className="raunded bgOrange"></div>
                        <span className="department_header_text">{orangeText}</span>
                    </div>
                    <div className="department_header">
                        <div className="raunded bgRed"></div>
                        <span>{redText}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationRow;
