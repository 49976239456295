import { memo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { nextList, setTimeOutId } from "../../features/ui/uiSlice";
import "./Progress.css";
import { UPDATE_DELAY_BY_SECOND } from "../../consts/consts";

const Progress = memo(() => {
    const progresBar = useRef();
    const dispatch = useDispatch();

    const update = () => {
        if (progresBar.current) {
            progresBar.current.innerHTML = "";
            const div = document.createElement("div");
            div.className = "progress_bar_animation_color";
            div.style.animationDuration = UPDATE_DELAY_BY_SECOND + "s";
            progresBar.current.appendChild(div);
        }
    };

    useEffect(() => {
        update();
        dispatch(
            setTimeOutId(
                setInterval(() => {
                    update();
                    dispatch(nextList());
                }, UPDATE_DELAY_BY_SECOND * 1000)
            )
        );
    }, []);

    return <div ref={progresBar} className="progress_bar"></div>;
});

export default Progress;
