import React, { useEffect } from "react";
import "./Updating.css";
import { useSelector } from "react-redux";

const Updating = () => {
    const { timeout } = useSelector((state) => state.ui);

    useEffect(() => {
        clearInterval(timeout);
    }, [timeout]);

    return (
        <div className="updating_block">
            <div className="updating_animate"></div>
            <div className="updating_content">Получение данных</div>
        </div>
    );
};

export default Updating;
