import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../axios/axios.config";
import moment from "moment/moment";

const initialState = {
    implementers: [],
    implementersLoad: [],
    counts: null,
    countsLoad: null,
    timeUpdated: null,
    timeUpdatedLoad: null,
};

export const getImplementers = createAsyncThunk("implementers/getAll", async (_, { dispatch }) => {
    try {
        const resp = await api.post("dashboard/getAll");
        dispatch(setImplementers(resp.data.data.implementers));
        dispatch(setCounts(resp.data.data.counts));

        const currentTime = moment().format("YYYY.MM.DD HH:mm");
        dispatch(setTimeUpdated(currentTime));

        return resp.data;
    } catch (e) {
        console.log(e);
    }
});

export const getImplementersLoad = createAsyncThunk("implementers/getAllLoad", async (_, { dispatch }) => {
    try {
        const resp = await api.post("dashboard/getAll");
        dispatch(setImplementersLoad(resp.data.data.implementers));
        dispatch(setCountsLoad(resp.data.data.counts));

        const currentTime = moment().format("YYYY.MM.DD HH:mm");
        dispatch(setTimeUpdatedLoad(currentTime));
        return resp.data;
    } catch (e) {
        console.log(e);
    }
});

const implementerSlice = createSlice({
    name: "implementers",
    initialState,
    reducers: {
        setImplementers: (state, { payload }) => {
            state.implementers = payload;
        },
        setCounts: (state, { payload }) => {
            state.counts = payload;
        },
        setImplementersLoad: (state, { payload }) => {
            state.implementersLoad = payload;
        },
        setCountsLoad: (state, { payload }) => {
            state.countsLoad = payload;
        },
        setTimeUpdated: (state, { payload }) => {
            state.timeUpdated = payload;
        },
        setTimeUpdatedLoad: (state, { payload }) => {
            state.timeUpdatedLoad = payload;
        },
        refreshData: (state) => {
            state.implementers = state.implementersLoad;
            state.counts = state.countsLoad;
            state.timeUpdated = state.timeUpdatedLoad;
            console.log("updated");
            console.log(state.implementersLoad);
        },
    },
});

export const { setImplementers, setCounts, setTimeUpdated, setImplementersLoad, setCountsLoad, setTimeUpdatedLoad, refreshData } = implementerSlice.actions;

export default implementerSlice.reducer;
